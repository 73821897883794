import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";

export default function HomeHeader() {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                gap: "40px",
                position: "relative",
            }}
        >
            <Container
                maxWidth="md"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    py: { xs: 5, md: 5 },
                    pb: "0%",
                    marginBottom: 0,
                }}
            >
                <Stack direction="column" sx={{ pt: { xs: 5, md: 5 }, gap: "16px" }}>
                    <Typography
                        component="h1"
                        variant="h5"
                        fontFamily={"Montserrat"}
                        fontWeight={700}
                        fontSize={{ xs: "20px", sm: "40px" }}
                        lineHeight={{ xs: "24px", sm: "48px" }}
                        textAlign="center"
                        color="#F3F3F4"
                    >
                        Entre inúmeras alternativas, escolha a que está mais próxima de você
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        fontFamily={"Montserrat"}
                        fontWeight={500}
                        fontSize={{ xs: "16px", sm: "16px" }}
                        lineHeight={{ xs: "20px", sm: "20px" }}
                        textAlign="center"
                        color="#D8D9FF"
                    >
                        Atendimento prioritário para todas as necessidades do seu projeto digital, simplicidade,
                        integração e o saldo das suas vendas disponível para você na mesma hora. Receba um convite para
                        fazer parte da Celetus preenchendo o cadastro abaixo
                    </Typography>
                </Stack>
            </Container>
            <Stack direction="column" sx={{ pt: 0, marginTop: 0 }}>
                <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    border-radius="4px"
                    size="small"
                    href="https://dash.celetus.com/signup"
                    style={{ height: 48 }}
                    sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: "14px",
                        lineHeight: "24px",
                        textDecoration: "none",
                        color: "#FFFFFF",
                        backgroundColor: "#1C52BD",
                        "&:hover": {
                            color: "#F2F2FF",
                            boxShadow: "0px 0px 32px 0px #18459EA3",
                            top: "-0.5px",
                        },
                        padding: "12px, 16px, 12px, 16px",
                        gap: 10,
                    }}
                >
                    Realizar cadastro gratuito
                </Button>
            </Stack>

            <Stack sx={{ padding: "40px 0px 120px opx", position: "static" }}>
                <Box
                    display="flex"
                    justifyContent="center"
                    justifyItems="center"
                    pl="120px"
                    sx={{ display: { xs: "none", md: "block" } }}
                >
                    <img
                        src="/assets/background/siteBackground1.png"
                        alt="Phone"
                        width="100%"
                        height="100%"
                        style={{ objectFit: "cover", objectPosition: "center" }}
                    />
                </Box>
                <Grid sx={{ display: { xs: "block", md: "none" } }}>
                    <img
                        src="/assets/background/phoneBackgroundSite.png"
                        alt="Phone"
                        width="312px"
                        height="761.21px"
                        // width="100%"
                        // height="100%"
                        style={{ objectPosition: "center" }}
                    />
                </Grid>
            </Stack>
        </Box>
    );
}
