import { Box } from "@mui/material";
import DesktopLayout from "../../../layouts/partials/desktop.layout";
import HomeHeader from "../Components/homeHeader";
import HomeSection1 from "../Components/homeSection1";
import DesktopLayoutFooter from "../../../layouts/partials/desktop.layout.footer";
import HomeSection2 from "../Components/homeSection2";
import HomeSection3 from "../Components/homeSection3";

function NewHomePage() {
    return (
        <Box>
            <Box>
                {" "}
                <DesktopLayout />
            </Box>
            {/* header */}
            <Box
                display="flex"
                sx={{
                    backgroundImage: `url(/assets/background/backgroundSite.png)`,
                    pt: { xs: 5, md: 10 },
                }}
            >
                <HomeHeader />
            </Box>
            {/* Section 1 */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "#FFFFFF",
                    marginTop: { xs: "-255px", md: "-150px" },
                    pt: { xs: 5, md: 10 },
                }}
            >
                <HomeSection1 />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "#070819",
                }}
            >
                <HomeSection2 />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "#070819",
                }}
            >
                <HomeSection3 />
            </Box>
            <Box>
                <DesktopLayoutFooter />
            </Box>
        </Box>
    );
}
export default NewHomePage;
