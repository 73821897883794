import { BottomNavigation, Box, Container, Stack, Typography } from "@mui/material";

interface Props {
    window?: () => Window;
}

export default function DesktopLayoutFooter(props: Props) {
    return (
        <Box sx={{ backgroundColor: "#070819" }}>
            <Container>
                <BottomNavigation
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        height: "auto",
                        weight: "auto",
                        backgroundColor: "#070819",
                        justifyContent: { xs: "center", md: "space-between" },
                        paddingTop: "30px",
                        paddingBottom: "30px",
                        paddingRight: { xs: "20px", md: "0px" },
                        paddingLeft: { xs: "20px", md: "0px" },
                    }}
                >
                    <Stack
                        sx={{
                            display: "flex",
                            direction: "column",
                            backgroundColor: "#070819",
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            fontFamily={"Montserrat"}
                            fontWeight={400}
                            fontSize={{ xs: "12px", md: "16px" }}
                            lineHeight={{ xs: "16px", md: "20px" }}
                            textAlign={{ xs: "center", md: "left" }}
                            color="#D8D9FF"
                        >
                            Celetus - 2024 © Todos os direitos reservados
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            fontFamily={"Montserrat"}
                            fontWeight={400}
                            fontSize={{ xs: "12px", md: "16px" }}
                            lineHeight={{ xs: "16px", md: "20px" }}
                            textAlign={{ xs: "center", md: "left" }}
                            color="#D8D9FF"
                        >
                            Celetus Tecnologia e Educação Ltda
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            fontFamily={"Montserrat"}
                            fontWeight={400}
                            fontSize={{ xs: "12px", md: "16px" }}
                            lineHeight={{ xs: "16px", md: "20px" }}
                            textAlign={{ xs: "center", md: "left" }}
                            color="#D8D9FF"
                        >
                            CNPJ nº 49.994.727/0001-20
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            fontFamily={"Montserrat"}
                            fontWeight={400}
                            fontSize={{ xs: "12px", md: "16px" }}
                            lineHeight={{ xs: "16px", md: "20px" }}
                            textAlign={{ xs: "center", md: "left" }}
                            color="#D8D9FF"
                        >
                            Endereço: R C24 119, QUADRA 30 LOT E01 CASA 01 SALA1
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            fontFamily={"Montserrat"}
                            fontWeight={400}
                            fontSize={{ xs: "12px", md: "16px" }}
                            lineHeight={{ xs: "16px", md: "20px" }}
                            textAlign={{ xs: "center", md: "left" }}
                            color="#D8D9FF"
                        >
                            Goiânia-GO, CEP 74265140
                        </Typography>
                    </Stack>
                    <Stack justifyContent="center">
                        <Typography
                            variant="subtitle1"
                            fontFamily={"Montserrat"}
                            fontWeight={400}
                            fontSize={{ xs: "12px", md: "16px" }}
                            lineHeight={{ xs: "16px", md: "20px" }}
                            textAlign={{ xs: "center", md: "right" }}
                            alignItems="center"
                            color="#D8D9FF"
                        >
                            Contato: contato@celetus.com
                        </Typography>
                    </Stack>
                </BottomNavigation>
            </Container>
        </Box>
    );
}
