import { Button, Container, Stack, Typography } from "@mui/material";

export default function HomeSection1() {
    return (
        <Container
            sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: "center",
                justifyContent: { xs: "row", md: "space-between" },
                gap: "32px",
                pt: { xs: 30, md: 20 },
                py: { xs: 30, md: 20 },
            }}
        >
            <Stack
                sx={{
                    width: { xs: "auto ", md: "392px" },
                    height: { xs: "auto ", md: "320px" },
                    gap: "32px",
                }}
            >
                <Typography
                    component="h1"
                    variant="h5"
                    fontFamily={"Montserrat"}
                    fontWeight={700}
                    fontSize={{ xs: "24px", md: "32px" }}
                    lineHeight={{ xs: "32px", md: "40px" }}
                    textAlign="left"
                    color="#38393B"
                >
                    Só quem já passou por algum problema com uma plataforma de pagamentos sabe que tempo é igual a
                    dinheiro...
                </Typography>

                <Stack sx={{ display: { xs: "none", md: "block" } }}>
                    <Button
                        type="submit"
                        variant="contained"
                        disableElevation
                        border-radius="4px"
                        size="small"
                        href="https://dash.celetus.com/signup"
                        sx={{
                            width: "220px",
                            height: "48px",
                            borderRadius: "4px",
                            paddingTop: "12px 16px 12px 16px",
                            gap: "10px",
                            backgroundColor: "#1C52BD",
                            "&:hover": {
                                color: "#FFFFFF",
                                boxShadow: "0px 0px 32px 0px #18459EA3",
                                top: "-0.5px",
                            },
                            fontFamily: "Montserrat",
                            fontHeight: "24px",
                            fontWidth: "164px",
                            fontWeight: "700",

                            fontSize: "14px",
                            lineHeight: "24px",
                        }}
                    >
                        Acesse Gratuitamente
                    </Button>
                </Stack>
            </Stack>
            <Stack
                sx={{
                    width: { xs: "auto", md: "392px" },
                    height: { xs: "auto", md: "240px" },
                    gap: "0px",
                    component: "h1",
                    variant: "h5",
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#5D5E61",
                }}
            >
                <Typography fontFamily={"Montserrat"}>
                    Para ajudar você a não perder nenhuma venda a Celetus oferece suporte exclusivo independente se você
                    é um pequeno ou grande produtor.
                </Typography>
                <br></br>
                <Typography fontFamily={"Montserrat"}>
                    Temos checkout de processamento rápido, ou seja, mais vendas e menos evasão.
                </Typography>
                <br></br>
                <Typography fontFamily={"Montserrat"}>
                    Desenvolvemos uma tecnologia exclusiva para gateway de pagamentos para garantir o máximo de
                    aprovações simultâneas.
                </Typography>
            </Stack>
            <Stack sx={{ display: { xs: "block", md: "none" } }}>
                <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    border-radius="4px"
                    size="small"
                    href="https://dash.celetus.com/signup"
                    sx={{
                        width: "220px",
                        height: "48px",
                        borderRadius: "4px",
                        paddingTop: "12px 16px 12px 16px",
                        gap: "10px",
                        backgroundColor: "#1C52BD",
                        "&:hover": {
                            color: "#FFFFFF",
                            boxShadow: "0px 0px 32px 0px #18459EA3",
                            top: "-0.5px",
                        },
                        fontFamily: "Montserrat",
                        fontHeight: "24px",
                        fontWidth: "164px",
                        fontWeight: "700",

                        fontSize: "14px",
                        lineHeight: "24px",
                    }}
                >
                    Acesse Gratuitamente
                </Button>
            </Stack>
        </Container>
    );
}
