import { Box, Button, Container, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useEffect, useState } from "react";

export default function HomeSection3() {
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.onresize = () => {
            setInnerWidth(window.innerWidth);
        };
    }, []);

    const RegisterButton = () => (
        <Button
            fullWidth
            type="submit"
            variant="contained"
            disableElevation
            border-radius="4px"
            size="small"
            href="https://dash.celetus.com/signup"
            sx={{
                height: "48px",
                borderRadius: "4px",
                paddingTop: "12px 16px 12px 16px",
                gap: "10px",
                backgroundColor: "#1C52BD",
                "&:hover": {
                    color: "#FFFFFF",
                    boxShadow: "0px 0px 32px 0px #18459EA3",
                    top: "-0.5px",
                },
                fontFamily: "Montserrat",
                fontHeight: "24px",
                fontWidth: "194px",
                fontWeight: "700",
                fontSize: "12px",
                lineHeight: "24px",
            }}
        >
            Inscreva-se Gratuitamente
        </Button>
    );

    return (
        <Container>
            {/* web */}
            <Box width="100%" sx={{ display: { xs: "none", lg: "block" } }}>
                <Box
                    sx={{
                        position: "absolute",
                        left: "-15px",
                    }}
                >
                    <img
                        id="top"
                        src="/assets/background/webGraphicElementTop.png"
                        alt="Elemento do topo"
                        style={{
                            objectFit: "contain",
                            height: "100%",
                            width: "100%",
                        }}
                    />
                </Box>

                <Grid container columns={12}>
                    <Grid md={3.5}>
                        <Stack
                            direction="column"
                            spacing="32px"
                            height="100%"
                            width="100%"
                            justifyContent="center"
                            sx={{ pl: innerWidth >= 1200 && innerWidth <= 1300 ? 8 : 0 }}
                        >
                            <Stack>
                                <Typography
                                    variant="h2"
                                    fontFamily={"Montserrat"}
                                    fontWeight={700}
                                    fontSize="48px"
                                    lineHeight="56px"
                                    textAlign="start"
                                    color="#F3F3F4"
                                >
                                    Como se cadastrar na Celetus?
                                </Typography>
                            </Stack>
                            <Stack>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disableElevation
                                    border-radius="4px"
                                    size="small"
                                    href="https://dash.celetus.com/signup"
                                    sx={{
                                        width: "227px",
                                        height: "48px",
                                        borderRadius: "4px",
                                        paddingTop: "12px 16px 12px 16px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "10px",
                                        backgroundColor: "#1C52BD",
                                        "&:hover": {
                                            color: "#FFFFFF",
                                            boxShadow: "0px 0px 32px 0px #18459EA3",
                                            top: "-0.5px",
                                        },
                                        fontFamily: "Montserrat",
                                        fontHeight: "24px",
                                        fontWidth: "194px",
                                        fontWeight: "700",
                                        fontSize: "12px",
                                        lineHeight: "24px",
                                    }}
                                >
                                    Inscreva-se Gratuitamente
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid md={3.5}>
                        <Box width="100%" height="100%" sx={{ py: 1 }}>
                            <img
                                id="middle"
                                src="/assets/background/homem1.png"
                                alt=""
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "cover",
                                    objectPosition: "top",
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid md={5}>
                        <Stack
                            direction="column"
                            spacing="16px"
                            height="100%"
                            width="100%"
                            justifyContent="center"
                            sx={{ pl: "80px" }}
                        >
                            <Box
                                display="flex"
                                sx={{
                                    alignItems: "flex-start",
                                    borderRadius: "16px",
                                    backgroundColor: "#FFFFFF0A",
                                    width: "100%",
                                    padding: "16px",
                                    gap: "16px",
                                }}
                            >
                                <Stack sx={{ width: "48px" }}>
                                    <img
                                        src="assets\background\mail.svg"
                                        alt="mail"
                                        style={{
                                            maxWidth: "40px",
                                            height: "40px%",
                                            objectPosition: "top",
                                        }}
                                    />
                                </Stack>
                                <Stack
                                    sx={{
                                        gap: "8x",
                                        width: "auto",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontFamily={"Montserrat"}
                                        fontWeight={700}
                                        fontSize="16px"
                                        lineHeight="20px"
                                        textAlign="start"
                                        color="#F3F3F4"
                                        paddingBottom="10px"
                                    >
                                        Receba um convite
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        fontFamily={"Montserrat"}
                                        fontWeight={400}
                                        fontSize="14px"
                                        lineHeight="18px"
                                        textAlign="start"
                                        color="#D8D9FF"
                                    >
                                        A inscrição é simples e totalmente gratuita, insira seus dados pessoais e os
                                        dados do seu negócio que estão completamente seguros aqui.
                                    </Typography>
                                </Stack>
                            </Box>

                            <Box
                                display="flex"
                                sx={{
                                    alignItems: "flex-start",
                                    borderRadius: "16px",
                                    backgroundColor: "#FFFFFF0A",
                                    width: "100%",
                                    padding: "16px",
                                    gap: "16px",
                                }}
                            >
                                <Stack sx={{ height: "100%", width: "48px" }}>
                                    <img
                                        src="assets\background\stars.svg"
                                        alt="stars"
                                        style={{
                                            maxWidth: "40px",
                                            height: "40px",
                                            objectPosition: "top",
                                        }}
                                    />
                                </Stack>
                                <Stack
                                    sx={{
                                        gap: "8x",
                                        width: "auto",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontFamily={"Montserrat"}
                                        fontWeight={700}
                                        fontSize="16px"
                                        lineHeight="20px"
                                        textAlign="start"
                                        color="#F3F3F4"
                                        paddingBottom="10px"
                                    >
                                        Crie sua conta
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        fontFamily={"Montserrat"}
                                        fontWeight={400}
                                        fontSize="14px"
                                        lineHeight="18px"
                                        textAlign="start"
                                        color="#D8D9FF"
                                    >
                                        A inscrição é simples e totalmente gratuita, insira seus dados pessoais e os
                                        dados do seu negócio que estão completamente seguros aqui.
                                    </Typography>
                                </Stack>
                            </Box>

                            <Box
                                display="flex"
                                sx={{
                                    alignItems: "flex-start",
                                    borderRadius: "16px",
                                    backgroundColor: "#FFFFFF0A",
                                    width: "100%",
                                    padding: "16px",
                                    gap: "16px",
                                }}
                            >
                                <Stack sx={{ height: "100%", width: "48px" }}>
                                    <img
                                        src="assets\background\setting.svg"
                                        alt="setting"
                                        style={{
                                            maxWidth: "40px",
                                            height: "40px",
                                            objectPosition: "top",
                                        }}
                                    />
                                </Stack>
                                <Stack
                                    sx={{
                                        gap: "8x",
                                        width: "auto",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontFamily={"Montserrat"}
                                        fontWeight={700}
                                        fontSize="16px"
                                        lineHeight="20px"
                                        textAlign="start"
                                        color="#F3F3F4"
                                        paddingBottom="10px"
                                    >
                                        Configure seu produto
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        fontFamily={"Montserrat"}
                                        fontWeight={400}
                                        fontSize="14px"
                                        lineHeight="18px"
                                        textAlign="start"
                                        color="#D8D9FF"
                                    >
                                        Seja ele um ebook, cursos online, mentorias, serviços ou produtos físicos.
                                        Defina o valor e as formas de pagamento, e seu produto está pronto e disponível
                                        para vender. Você não vai precisar aguardar dias para que seu produto seja
                                        aprovado.
                                    </Typography>
                                </Stack>
                            </Box>

                            <Box
                                display="flex"
                                sx={{
                                    alignItems: "flex-start",
                                    borderRadius: "16px",
                                    backgroundColor: "#FFFFFF0A",
                                    width: "100%",
                                    padding: "16px",
                                    gap: "16px",
                                }}
                            >
                                <Stack sx={{ height: "100%", width: "48px" }}>
                                    <img
                                        src="assets\background\orderBump.svg"
                                        alt="orderBump"
                                        style={{
                                            maxWidth: "40px",
                                            height: "40px",
                                            objectPosition: "top",
                                        }}
                                    />
                                </Stack>
                                <Stack
                                    sx={{
                                        gap: "8x",
                                        width: "auto",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontFamily={"Montserrat"}
                                        fontWeight={700}
                                        fontSize="16px"
                                        lineHeight="20px"
                                        textAlign="start"
                                        color="#F3F3F4"
                                        paddingBottom="10px"
                                    >
                                        Comece a vender
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        fontFamily={"Montserrat"}
                                        fontWeight={400}
                                        fontSize="14px"
                                        lineHeight="18px"
                                        textAlign="start"
                                        color="#D8D9FF"
                                    >
                                        Somos a combinação perfeita para a sua estratégia de marketing e vendas, agora
                                        chegou a hora de promover seu produto e ver as vendas caírem na notificação do
                                        seu celular.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>

            {/* Mobile/Tablets/Ipads */}
            <Box sx={{ display: { xs: "block", lg: "none" } }}>
                <Box
                    sx={{
                        position: "absolute",
                        left: "0px",
                        display: { xs: "block", lg: "none" },
                    }}
                >
                    <img
                        id="top"
                        src="/assets/background/mobileGraphicElementTop.png"
                        alt="Elemento do topo"
                        style={{
                            objectFit: "contain",
                            height: "100%",
                            width: "100%",
                        }}
                    />
                </Box>

                <Box sx={{ p: "8px" }}>
                    <Grid
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        gap="32px"
                        paddingTop={{ xs: "25%", sm: "15%" }}
                        paddingBottom="5%"
                    >
                        <Stack
                            sx={{
                                width: { xs: "312px ", md: "550px" },
                                height: "auto",
                            }}
                        >
                            <Typography
                                variant="h2"
                                fontFamily={"Montserrat"}
                                fontWeight={700}
                                fontSize={{ xs: "24px", md: "48px" }}
                                lineHeight={{ xs: "32px", md: "56px" }}
                                textAlign="center"
                                color="#F3F3F4"
                            >
                                Como se cadastrar na Celetus?
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid container spacing={2} columns={12}>
                        <Grid sm={6}>
                            <Box
                                display="flex"
                                sx={{
                                    alignItems: "flex-start",
                                    borderRadius: "16px",
                                    backgroundColor: "#FFFFFF0A",
                                    width: "auto",
                                    height: "100%",
                                    padding: "16px",
                                    gap: "16px",
                                }}
                            >
                                <Stack sx={{ height: "100%", width: "48px" }}>
                                    <img
                                        src="assets\background\mail.svg"
                                        alt="mail"
                                        style={{
                                            maxWidth: "40px",
                                            height: "40px%",
                                            objectPosition: "top",
                                        }}
                                    />
                                </Stack>
                                <Stack
                                    sx={{
                                        gap: "8x",
                                        width: "auto",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontFamily={"Montserrat"}
                                        fontWeight={700}
                                        fontSize="16px"
                                        lineHeight="20px"
                                        textAlign="start"
                                        color="#F3F3F4"
                                        paddingBottom="10px"
                                    >
                                        Receba um convite
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        fontFamily={"Montserrat"}
                                        fontWeight={400}
                                        fontSize="14px"
                                        lineHeight="18px"
                                        textAlign="start"
                                        color="#D8D9FF"
                                    >
                                        A inscrição é simples e totalmente gratuita, insira seus dados pessoais e os
                                        dados do seu negócio que estão completamente seguros aqui.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>

                        <Grid sm={6} paddingLeft="1%">
                            <Box
                                display="flex"
                                sx={{
                                    alignItems: "flex-start",
                                    borderRadius: "16px",
                                    backgroundColor: "#FFFFFF0A",
                                    width: "auto",
                                    height: "100%",
                                    padding: "16px",
                                    gap: "16px",
                                }}
                            >
                                <Stack sx={{ height: "100%", width: "48px" }}>
                                    <img
                                        src="assets\background\stars.svg"
                                        alt="stars"
                                        style={{
                                            maxWidth: "40px",
                                            height: "40px%",
                                            objectPosition: "top",
                                        }}
                                    />
                                </Stack>
                                <Stack
                                    sx={{
                                        gap: "8x",
                                        width: "auto",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontFamily={"Montserrat"}
                                        fontWeight={700}
                                        fontSize="16px"
                                        lineHeight="20px"
                                        textAlign="start"
                                        color="#F3F3F4"
                                        paddingBottom="10px"
                                    >
                                        Crie sua conta
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        fontFamily={"Montserrat"}
                                        fontWeight={400}
                                        fontSize="14px"
                                        lineHeight="18px"
                                        textAlign="start"
                                        color="#D8D9FF"
                                    >
                                        A inscrição é simples e totalmente gratuita, insira seus dados pessoais e os
                                        dados do seu negócio que estão completamente seguros aqui.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>

                        <Grid sm={6}>
                            <Box
                                display="flex"
                                sx={{
                                    alignItems: "flex-start",
                                    borderRadius: "16px",
                                    backgroundColor: "#FFFFFF0A",
                                    width: "auto",
                                    height: "100%",
                                    padding: "16px",
                                    gap: "16px",
                                }}
                            >
                                <Stack sx={{ height: "100%", width: "48px" }}>
                                    <img
                                        src="assets\background\setting.svg"
                                        alt="setting"
                                        style={{
                                            maxWidth: "40px",
                                            height: "40px%",
                                            objectPosition: "top",
                                        }}
                                    />
                                </Stack>
                                <Stack
                                    sx={{
                                        gap: "8x",
                                        width: "auto",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontFamily={"Montserrat"}
                                        fontWeight={700}
                                        fontSize="16px"
                                        lineHeight="20px"
                                        textAlign="start"
                                        color="#F3F3F4"
                                        paddingBottom="10px"
                                    >
                                        Configure seu produto
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        fontFamily={"Montserrat"}
                                        fontWeight={400}
                                        fontSize="14px"
                                        lineHeight="18px"
                                        textAlign="start"
                                        color="#D8D9FF"
                                    >
                                        Seja ele um ebook, cursos online, mentorias, serviços ou produtos físicos.
                                        Defina o valor e as formas de pagamento, e seu produto está pronto e disponível
                                        para vender. Você não vai precisar aguardar dias para que seu produto seja
                                        aprovado.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>

                        <Grid sm={6}>
                            <Box
                                display="flex"
                                sx={{
                                    alignItems: "flex-start",
                                    borderRadius: "16px",
                                    backgroundColor: "#FFFFFF0A",
                                    width: "auto",
                                    height: "100%",
                                    padding: "16px",
                                    gap: "16px",
                                }}
                            >
                                <Stack sx={{ height: "100%", width: "48px" }}>
                                    <img
                                        src="assets\background\orderBump.svg"
                                        alt="orderBump"
                                        style={{
                                            maxWidth: "40px",
                                            height: "40px%",
                                            objectPosition: "top",
                                        }}
                                    />
                                </Stack>
                                <Stack
                                    sx={{
                                        gap: "8x",
                                        width: "auto",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontFamily={"Montserrat"}
                                        fontWeight={700}
                                        fontSize="16px"
                                        lineHeight="20px"
                                        textAlign="start"
                                        color="#F3F3F4"
                                        paddingBottom="10px"
                                    >
                                        Comece a vender
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        fontFamily={"Montserrat"}
                                        fontWeight={400}
                                        fontSize="14px"
                                        lineHeight="18px"
                                        textAlign="start"
                                        color="#D8D9FF"
                                    >
                                        Somos a combinação perfeita para a sua estratégia de marketing e vendas, agora
                                        chegou a hora de promover seu produto e ver as vendas caírem na notificação do
                                        seu celular.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Stack direction="row" width="100%" alignItems="center" justifyContent="center" sx={{ mt: 4 }}>
                            <Box sx={{ display: { xs: "block", sm: "none" }, width: "100%" }}>
                                <RegisterButton />
                            </Box>
                            <Box sx={{ display: { xs: "none", sm: "block" }, width: "18rem" }}>
                                <RegisterButton />
                            </Box>
                        </Stack>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}
