import { Box, Container, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

export default function HomeSection2() {
    return (
        <Container
            sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: "center",
                justifyContent: "center",
                padding: "120px 24px 120px 24px",
                py: { xs: 5, md: 15 },
            }}
        >
            <Box>
                <Grid display="flex" justifyContent="center" sx={{ paddingBottom: { xs: "50px", md: "100px" } }}>
                    <Stack
                        sx={{
                            width: { xs: "312px ", md: "auto" },
                            height: { xs: "150px ", md: "auto" },

                            gap: "16px",
                        }}
                    >
                        <Typography
                            variant="h5"
                            fontFamily={"Montserrat"}
                            fontWeight={700}
                            fontSize={{ xs: "24px", md: "48px" }}
                            lineHeight={{ xs: "32px", md: "56px" }}
                            textAlign="center"
                            color="#F3F3F4"
                        >
                            A Celetus possui tudo que você precisa e muito mais
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            fontFamily={"Montserrat"}
                            fontWeight={500}
                            fontSize={{ xs: "16px", sm: "16px" }}
                            lineHeight={{ xs: "20px", sm: "20px" }}
                            textAlign="center"
                            color="#F3F3F4"
                        >
                            Nossa plataforma é simples e fácil de usar
                        </Typography>
                    </Stack>
                </Grid>

                {/*Images*/}
                <Grid container spacing={2} columns={12}>
                    <Grid md={6}>
                        <Box
                            sx={{
                                borderRadius: "20px",
                                backgroundColor: "#FFFFFF0A",
                                width: "100%",
                                height: "100%",
                                px: "14px",
                                gap: "40px",
                            }}
                        >
                            <Stack sx={{ gap: "12px", height: { xs: "auto", md: "250px" }, py: "48px" }}>
                                <Typography
                                    variant="body1"
                                    fontFamily={"Montserrat"}
                                    fontWeight={600}
                                    fontSize={{ xs: "16px", sm: "24px" }}
                                    lineHeight={{ xs: "20px", sm: "32px" }}
                                    textAlign="center"
                                    color="#F3F3F4"
                                    paddingBottom="10px"
                                >
                                    Crie funis de Upsell e Downsell
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    fontFamily={"Montserrat"}
                                    fontWeight={500}
                                    fontSize={{ xs: "12px", sm: "16px" }}
                                    lineHeight={{ xs: "16px", sm: "20px" }}
                                    textAlign="center"
                                    color="#D8D9FF"
                                >
                                    Você tem um modelo de negócio, nós temos um processador de pagamentos que facilita a
                                    criação de funis de vendas
                                </Typography>
                            </Stack>
                            <Stack sx={{ height: { xs: "auto", sm: "377px" }, width: "100%" }}>
                                <img
                                    src="assets\background\landingPageImg1.png"
                                    alt="Image1"
                                    style={{
                                        maxWidth: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                        objectPosition: "bottom",
                                    }}
                                />
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid md={6}>
                        <Box
                            sx={{
                                borderRadius: "20px",
                                backgroundColor: "#FFFFFF0A",
                                width: "100%",
                                height: "100%",
                                px: "32px",
                                gap: "40px",
                            }}
                        >
                            <Stack sx={{ gap: "12px", height: { xs: "auto", md: "250px" }, py: "48px" }}>
                                <Typography
                                    variant="body1"
                                    fontFamily={"Montserrat"}
                                    fontWeight={600}
                                    fontSize={{ xs: "16px", sm: "24px" }}
                                    lineHeight={{ xs: "20px", sm: "32px" }}
                                    textAlign="center"
                                    color="#F3F3F4"
                                    paddingBottom="10px"
                                >
                                    Personalize seus order bumps
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    fontFamily={"Montserrat"}
                                    fontWeight={500}
                                    fontSize={{ xs: "12px", sm: "16px" }}
                                    lineHeight={{ xs: "16px", sm: "20px" }}
                                    textAlign="center"
                                    color="#D8D9FF"
                                >
                                    Você vai se surpreender como um simples incremento na oferta pode aumentar o ticket
                                    médio do seu negócio!
                                </Typography>
                            </Stack>
                            <Stack sx={{ height: { xs: "auto", sm: "377px" }, width: "100%" }}>
                                <img
                                    src="assets\background\landingPageImg2.png"
                                    alt="Image2"
                                    style={{
                                        maxWidth: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                        objectPosition: "bottom",
                                    }}
                                />
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid md={6}>
                        <Box
                            sx={{
                                borderRadius: "20px",
                                backgroundColor: "#FFFFFF0A",
                                width: "100%",
                                height: "100%",
                                px: "32px",
                                gap: "40px",
                            }}
                        >
                            <Stack sx={{ gap: "12px", height: { xs: "auto", md: "250px" }, py: "48px" }}>
                                <Typography
                                    variant="body1"
                                    fontFamily={"Montserrat"}
                                    fontWeight={600}
                                    fontSize={{ xs: "16px", sm: "24px" }}
                                    lineHeight={{ xs: "20px", sm: "32px" }}
                                    textAlign="center"
                                    color="#F3F3F4"
                                    paddingBottom="10px"
                                >
                                    Conecte seu produto com tudo que você precisa
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    fontFamily={"Montserrat"}
                                    fontWeight={500}
                                    fontSize={{ xs: "12px", sm: "16px" }}
                                    lineHeight={{ xs: "16px", sm: "20px" }}
                                    textAlign="center"
                                    color="#D8D9FF"
                                >
                                    Ferramenta de e-mails, área de membros, traqueamento de leads e muito mais, aqui é
                                    muito simples fazer integrações com outras plataformas,
                                </Typography>
                            </Stack>
                            <Stack sx={{ height: { xs: "auto", sm: "377px" }, width: "100%" }}>
                                <img
                                    src="assets\background\landingPageImg3.png"
                                    alt="Image3"
                                    style={{
                                        maxWidth: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                        objectPosition: "bottom",
                                    }}
                                />
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid md={6}>
                        <Box
                            sx={{
                                borderRadius: "20px",
                                backgroundColor: "#FFFFFF0A",
                                width: "100%",
                                height: "100%",
                                px: "14px",
                                gap: "40px",
                            }}
                        >
                            <Stack sx={{ gap: "12px", height: { xs: "auto", md: "250px" }, py: "48px" }}>
                                <Typography
                                    variant="body1"
                                    fontFamily={"Montserrat"}
                                    fontWeight={600}
                                    fontSize={{ xs: "16px", sm: "24px" }}
                                    lineHeight={{ xs: "20px", sm: "32px" }}
                                    textAlign="center"
                                    color="#F3F3F4"
                                    paddingBottom="10px"
                                >
                                    Seu dinheiro disponivel em pouco tempo
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    fontFamily={"Montserrat"}
                                    fontWeight={500}
                                    fontSize={{ xs: "12px", sm: "16px" }}
                                    lineHeight={{ xs: "16px", sm: "20px" }}
                                    textAlign="center"
                                    color="#D8D9FF"
                                >
                                    As suas vendas via Pix são liberadas imediatamente e as vendas via cartão ou boleto
                                    você pode receber a partir de 2 dias. 
                                </Typography>
                            </Stack>
                            <Stack sx={{ height: { xs: "auto", sm: "377px" }, width: "100%" }}>
                                <img
                                    src="assets\background\landingPageImg4.png"
                                    alt="Image4"
                                    style={{
                                        maxWidth: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                        objectPosition: "bottom",
                                    }}
                                />
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}
