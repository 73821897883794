import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Stack, useScrollTrigger } from "@mui/material";

interface Props {
    window?: () => Window;
}

export default function DesktopLayout(props: Props) {
    const { window } = props;
    const [loading, setLoading] = React.useState(false);
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    type Anchor = "bottom";
    const [state, setState] = React.useState({
        bottom: false,
    });
    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.MouseEvent) => {
        if (event.type === "keydown") {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    return (
        <AppBar
            position="fixed"
            elevation={0}
            color="transparent"
            sx={{
                borderBottom: "1px solid #F2F2FF0A",
                backgroundColor: trigger ? "#061125" : "transparent",
            }}
        >
            <Container maxWidth="md">
                <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
                    <Stack width="20%" height="20%" sx={{ display: "flex" }}>
                        <img
                            src="/assets/logo/logo-white.svg"
                            alt="Logo"
                            style={{
                                width: "100%",
                                height: "24px",
                                objectFit: "contain",
                                objectPosition: "center",
                            }}
                        />
                    </Stack>

                    <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}></Box>

                    <Box sx={{ display: "flex", direction: "row" }}>
                        <Stack sx={{ paddingRight: "10px", display: { xs: "none", sm: "block" } }}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="outlined"
                                border-radius="4px"
                                size="small"
                                href="https://dash.celetus.com"
                                style={{ height: 50 }}
                                sx={{
                                    fontFamily: "Montserrat",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    lineHeight: "24px",
                                    color: "#F2F2FF",
                                    borderColor: "#F2F2FF",
                                    "&:hover": {
                                        boxShadow: "0px 0px 32px 0px #18459EA3",
                                        top: "-0.5px",
                                    },
                                }}
                            >
                                Login
                            </Button>
                        </Stack>

                        <Stack sx={{ display: { xs: "none", sm: "block" } }}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                border-radius="4px"
                                size="small"
                                href="https://dash.celetus.com/signup"
                                style={{ height: 50 }}
                                sx={{
                                    gap: "10px",
                                    fontFamily: "Montserrat",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    lineHeight: "24px",
                                    textDecoration: "none",
                                    color: "#FFFFFF",
                                    backgroundColor: "#1C52BD",
                                    "&:hover": {
                                        color: "#F2F2FF",
                                        boxShadow: "0px 0px 32px 0px #18459EA3",
                                        top: "-0.5px",
                                    },
                                }}
                            >
                                Cadastre-se Agora
                            </Button>
                        </Stack>
                        <Box sx={{ display: { xs: "block", sm: "none" } }}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="outlined"
                                border-radius="4px"
                                size="small"
                                href="https://dash.celetus.com"
                                style={{ height: 50 }}
                                sx={{
                                    fontFamily: "Montserrat",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    lineHeight: "24px",
                                    color: "#F2F2FF",
                                    borderColor: "#F2F2FF",
                                    "&:hover": {
                                        boxShadow: "0px 0px 32px 0px #18459EA3",
                                        top: "-0.5px",
                                    },
                                }}
                            >
                                Entrar
                            </Button>
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
